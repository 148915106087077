import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';

class Support extends React.Component {

    compontentDidMount(){
        document.title = "Support | Tobermory Brewing ";
    }

    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Support</div>
                </div>
                <div className="text-center">
                    <h3>Need help? Call or email us 24/7</h3>
                    <h4>
                        <p>519-857-3209‬</p>
                        <p><b>By Email:</b> <a href="mailto:orders@tobermorybrewingcompany.com">orders@tobermorybrewingcompany.com</a></p>
                    </h4>
                </div>
            </ContentWrapper>
        );
    }
}

export default (Support);